import { Fragment } from 'react';
import HeaderSection from '../../Layout/HeaderSection';
import PageFeaturesSection from './PageFeaturesSection'
import WayChooseSection from './WayChooseSection'

import GetInTouch from '../../Common/GetInTouch';
const data = require('../../../assets/database/DatabaseDesignAndManagement.json');
const HydraulicElevators = props => {
    return (
        <Fragment>
            <HeaderSection title="Database Design And Management" />
            <PageFeaturesSection page_name="DatabaseDesignAndManagement.json" />
            <WayChooseSection page_name="DatabaseDesignAndManagement.json" section_title="Why Choose Caperbit for Database Design and Management?" />
            <section className="text-gray-700 ark:bg-dark lg:pb-[90px]  pb-12 pt-10">
                <GetInTouch title="Get in Touch:" description={data.get_in_touch} />
            </section>
        </Fragment>
    );
};

export default HydraulicElevators;