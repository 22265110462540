import { Fragment } from 'react';
import { Link } from "react-router-dom";

const GetInTouch = props => {
    return (
        <Fragment>
            <div className="flex flex-col text-center w-full mb-20">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{props.title}</h1>
                {/* Get in Touch: */}
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">{props.description}</p>
                <Link to="/contact-us" className="flex mx-auto mt-16 text-white bg-black border-0 py-2 px-8 focus:outline-none hover:bg-blue-950 rounded text-lg">Contact Us</Link>
            </div>
        </Fragment>
    );
};

export default GetInTouch;