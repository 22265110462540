import { Fragment } from 'react';
import { Link } from "react-router-dom";

const Footer = props => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };
    const FooterSection = () => {
        const today = new Date();
        const year = today.getFullYear();
        return (
            <footer className="bg-gray-900">
                <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                    <div class="md:flex md:justify-between">
                        <div class="mb-6 md:mb-0">
                            <div className="flex justify-center text-teal-300 sm:justify-start">
                                <img src="logo-white.png" className='h-[100px] w-[70%]' />
                            </div>

                            <p className="max-w-md mx-auto mt-6 leading-relaxed text-center text-gray-400 sm:max-w-xs sm:mx-0 sm:text-left">
                                Caperbit is among the top players in transforming ideas into digital reality with innovative web & mobile solutions.
                            </p>
                        </div>
                        <div className="grid">
                            <div className="text-center sm:text-left">
                                <p className="text-lg font-medium text-white">Quick Links</p>
                                <nav className="mt-8">
                                    <ul className="space-y-4 text-sm">
                                        <li>
                                            <Link onClick={scrollToTop} className="text-white transition hover:text-white/75 scrollBtnBottom" to="/about">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={scrollToTop} className="text-white transition hover:text-white/75" to="/contact-us">
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="text-center sm:text-left">
                                <p className="text-lg font-medium text-white">Our Services</p>
                                <nav className="mt-8">
                                    <ul className="space-y-4 text-sm">
                                        <li>
                                            <Link onClick={scrollToTop} className="text-white transition hover:text-white/75" to="/web_application_development">
                                                Web Application Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={scrollToTop} className="text-white transition hover:text-white/75" to="/mobile_application_development">
                                                Mobile Application Development
                                            </Link>
                                        </li>

                                        <li>
                                            <Link onClick={scrollToTop} className="text-white transition hover:text-white/75" to="/api_application">
                                                API Development
                                            </Link>
                                        </li>

                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="text-center sm:text-left">
                                <p className="text-lg font-medium text-white">Our Services</p>
                                <nav className="mt-8">
                                    <ul className="space-y-4 text-sm">
                                        <li>
                                            <Link onClick={scrollToTop} className="text-white transition hover:text-white/75" to="/database_design_and_management">
                                                Database Design and Management
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={scrollToTop} className="text-white transition hover:text-white/75" to="/cloud_services_and_deployment">
                                                Cloud Services and Deployment
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 mt-12 border-t border-gray-800">
                        <div className="text-center sm:flex sm:justify-between sm:text-left">
                            <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
                                Copyright  &copy; {year}  - All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </footer >
        );
    };

    return (
        <Fragment>
            <FooterSection />
        </Fragment>
    );
};

export default Footer;