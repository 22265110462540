import { Fragment } from 'react';
import { Carousel } from "@material-tailwind/react";

const Testimonials = props => {


    const TestimonialSection = () => {

        return (
            <Fragment>
                <div className="flex flex-wrap">
                    <div className="w-full px-4 p-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                                Customer Feedback
                            </h2>
                        </div>
                    </div>
                </div>
                <Carousel
                    // autoplay="true"
                    // loop="true"
                    className="rounded-xl"
                >

                    <section className="bg-white  lg:px-8 overflow-hidden px-6 relative">
                        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20"></div>
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
                        <div className="mx-auto max-w-2xl lg:max-w-4xl">
                            <figure className="mt-10">
                                <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                                    <p>“The website development team exceeded our expectations. They delivered a sleek, user-friendly site that has significantly boosted our online presence and customer engagement. Highly recommend their services!”</p>
                                </blockquote>
                                <figcaption className="mt-10">
                                    <img src="services/our_customers.jpg" alt="" className="mx-auto h-10 w-10 rounded-full" loading="lazy" />
                                    <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                                        <div className="font-semibold text-gray-900">Mitin Patel</div>
                                        <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" className="fill-gray-900">
                                            <circle cx="1" cy="1" r="1" />
                                        </svg>
                                        <div className="text-gray-600">CEO of 4Matic Elevators</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </section>
                    <section className="bg-white  lg:px-8 overflow-hidden px-6 relative">
                        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20"></div>
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
                        <div className="mx-auto max-w-2xl lg:max-w-4xl">

                            <figure className="mt-10">
                                <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                                    <p>“The elevator operates smoothly and efficiently, meeting our building's needs.”</p>
                                </blockquote>
                                <figcaption className="mt-10">
                                    <img src="services/our_customers.jpg" alt="" className="mx-auto h-10 w-10 rounded-full" loading="lazy" />
                                    <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                                        <div className="font-semibold text-gray-900">Judith Black</div>
                                        <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" className="fill-gray-900">
                                            <circle cx="1" cy="1" r="1" />
                                        </svg>
                                        <div className="text-gray-600">CEO of Workcation</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </section>
                    <section className="bg-white  lg:px-8 overflow-hidden px-6 relative">
                        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20"></div>
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
                        <div className="mx-auto max-w-2xl lg:max-w-4xl">

                            <figure className="mt-10">
                                <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                                    <p>“"The elevator meets all safety standards, and we appreciate the company's commitment to ensuring passenger safety."
                                        Negative: "We're concerned about the outdated safety features of the elevator, and we'd like to see them upgraded.”</p>
                                </blockquote>
                                <figcaption className="mt-10">
                                    <img src="services/our_customers.jpg" alt="" className="mx-auto h-10 w-10 rounded-full" loading="lazy" />
                                    <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                                        <div className="font-semibold text-gray-900">Judith Black</div>
                                        <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" className="fill-gray-900">
                                            <circle cx="1" cy="1" r="1" />
                                        </svg>
                                        <div className="text-gray-600">CEO of Workcation</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </section>
                    <section className="bg-white  lg:px-8 overflow-hidden px-6 relative">
                        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20"></div>
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
                        <div className="mx-auto max-w-2xl lg:max-w-4xl">
                            <figure className="mt-10">
                                <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                                    <p>“Working with the elevator company has been a great experience overall. They've been responsive to our needs and provide excellent customer support."
                                        Negative: "Our experience with the elevator company has been disappointing. We've encountered multiple issues with installation, maintenance, and communication.”</p>
                                </blockquote>
                                <figcaption className="mt-10">
                                    <img src="services/our_customers.jpg" alt="" className="mx-auto h-10 w-10 rounded-full" loading="lazy" />
                                    <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                                        <div className="font-semibold text-gray-900">Judith Black</div>
                                        <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" className="fill-gray-900">
                                            <circle cx="1" cy="1" r="1" />
                                        </svg>
                                        <div className="text-gray-600">CEO of Workcation</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </section>
                </Carousel >
            </Fragment>

        );
    };

    return (
        <Fragment>
            <TestimonialSection />
        </Fragment>
    );
};

export default Testimonials;