import { Fragment } from 'react'
import { Link } from "react-router-dom";
const Header = props => {
    return <Fragment>
        <div className="sticky top-0 ud-header bg-white absolute left-0 top-0 z-40 flex-col-reverse flex w-full items-center bg-transparent">
            <div className="container">
                <div className="relative -mx-4 flex items-center justify-between">
                    <div className="w-60 max-w-full px-4">
                        <Link to="/" className="navbar-logo block w-full py-5">
                            <img src="logo.png" alt="logo" className="header-logo w-[70%]" />
                        </Link>
                    </div>

                    <div className="flex w-full items-center justify-between px-4">
                        <div>
                            <button
                                className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
                                type="button"
                                data-te-collapse-init
                                data-te-target="#navbarSupportedContent2"
                                aria-controls="navbarSupportedContent2"
                                aria-expanded="false"
                                aria-label="Toggle navigation">

                                <span className="[&>svg]:w-7">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="h-7 w-7">
                                        <path
                                            fillRule="evenodd"
                                            d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                                            clipRule="evenodd" />
                                    </svg>
                                </span>
                            </button>
                            <nav id="navbarCollapse"
                                className="absolute right-4 top-full hidden w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:px-4 lg:py-0 lg:shadow-none dark:lg:bg-transparent xl:px-6">
                                <ul className="blcok lg:flex 2xl:ml-20">
                                    <li className="group relative">
                                        <Link to="/"
                                            className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark: lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg: lg:group-hover: lg:group-hover:opacity-70 xl:ml-10">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="group relative">
                                        <Link to="/about"
                                            className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark: lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg: lg:group-hover: lg:group-hover:opacity-70 xl:ml-10">
                                            About
                                        </Link>
                                    </li>
                                    <li className="submenu-item group relative">
                                        <a href="#!"
                                            className="relative mx-8 flex items-center justify-between py-2 text-base font-medium text-dark group-hover:text-primary dark: lg:ml-8 lg:mr-0 lg:inline-flex lg:py-6 lg:pl-0 lg:pr-4 lg: lg:group-hover: lg:group-hover:opacity-70 xl:ml-10">
                                            Our Services
                                            <svg className="ml-2 fill-current" width="16" height="20" viewBox="0 0 16 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.99999 14.9C7.84999 14.9 7.72499 14.85 7.59999 14.75L1.84999 9.10005C1.62499 8.87505 1.62499 8.52505 1.84999 8.30005C2.07499 8.07505 2.42499 8.07505 2.64999 8.30005L7.99999 13.525L13.35 8.25005C13.575 8.02505 13.925 8.02505 14.15 8.25005C14.375 8.47505 14.375 8.82505 14.15 9.05005L8.39999 14.7C8.27499 14.825 8.14999 14.9 7.99999 14.9Z" />
                                            </svg>
                                        </a>
                                        <div className="submenu relative left-0 top-full hidden w-[200%] rounded-sm bg-white p-2 transition-[top] duration-300 group-hover:opacity-100 dark:bg-dark-2 lg:invisible lg:absolute lg:top-[110%] lg:block lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full">
                                            <div className="">
                                                <div className="grid grid-cols-1 lg:grid-cols-1 ">
                                                    <div className="p-2  text-gray-900 md:pb-4 dark:text-white">

                                                        <ul className="space-y-4 relative p-3" aria-labelledby="mega-menu-icons-dropdown-button">
                                                            <li>
                                                                <Link to="/web_application_development" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                                    <span className="sr-only">Web Application Development</span>
                                                                    Web Application Development
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/mobile_application_development" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                                    <span className="sr-only"> Mobile Application Development</span>
                                                                    Mobile Application Development
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/api_application" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                                    <span className="sr-only">API Development</span>
                                                                    API Development
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/database_design_and_management" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                                    <span className="sr-only">Database Design and Management</span>
                                                                    Database Design and Management
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/cloud_services_and_deployment" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                                    <span className="sr-only">Cloud Services and Deployment</span>
                                                                    Cloud Services and Deployment
                                                                </Link>
                                                            </li>



                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="group relative">
                                        <Link to="/contact-us"
                                            className="ud-menu-scroll mx-8 flex py-2 text-base font-medium text-dark group-hover:text-primary dark: lg:ml-7 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 lg: lg:group-hover: lg:group-hover:opacity-70 xl:ml-10">
                                            Contact Us
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="flex items-center justify-end pr-16 lg:pr-0">
                            <div style={{ backgroundColor: "#d21d33" }} className=" hidden sm:flex">
                                <Link to="/contact-us" className="loginBtn px-[22px] py-2 rounded-md text-base font-medium text-white hover:bg-blue-950">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment >
}
export default Header;