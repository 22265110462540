import { Fragment } from 'react';
import AboutCompany from "../../components/About/AboutCompany";
import OurService from "../../components/About/OurService";

const About = props => {
    return (
        <Fragment>
            <div className=" flex flex-wrap">
                <div className="w-full px-4 bg-gray-100 ">
                    <div className="max-w-2xl mx-auto p-10 text-center">
                        <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                            About Us
                        </h2>
                    </div>
                </div>
            </div>
            <AboutCompany />
            <OurService />
        </Fragment>
    );
};

export default About;

