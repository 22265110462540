import { Fragment } from 'react';
const WayChooseSection = props => {

    const data = require('../../../assets/database/' + props.page_name);
    const SectionData = data.way_choose_section;
    return (
        <Fragment>
            <section className="bg-gray-50 dark:bg-dark lg:pb-[90px]  pb-12 pt-10">
                <div className="container mx-auto">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="mb-12 mx-auto text-center">
                                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900"> {props.section_title}</h1>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap -m-4">
                        {SectionData.map((row) =>
                            <div className="xl:w-1/3 md:w-1/2 p-4">
                                <div className="mb-9 rounded-[20px] bg-white p-10 shadow-2 hover:shadow-lg dark:bg-dark-2 ">
                                    <h4 className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                                        {row.title}
                                    </h4>
                                    <p className="text-body-color dark:text-dark-6">{row.description}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default WayChooseSection;