import { Fragment } from 'react';
import { Link } from "react-router-dom";
const Banner = props => {

    const BannerSection = () => {

        return (
            <div id="home" className="overflow-hidden pt-16 relative">
                <div className="pb-12">
                    <div className="-mx-4 flex flex-wrap items-center">
                        <div className="w-full px-4">
                            <div className="fadeInUp hero-content max-w-7xl mx-auto text-center wow" data-wow-delay=".2s">
                                <h1 className="text-4xl leading-tight tracking-wide lg:text-5xl lg:leading-tight xl:text-6xl xl:leading-tight mb-6 text-3xl  leading-snug sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2]">
                                    Transforming Ideas into Digital Reality with Innovative Web & Mobile Solutions
                                </h1>
                                <p className="mx-auto mb-9 max-w-[600px] text-base font-medium sm:text-lg sm:leading-[1.44]">
                                    Empowering Your Digital Presence with Custom Web & Mobile Solutions

                                </p>
                                <ul className="mb-10 flex flex-wrap items-center justify-center gap-5">
                                    <li>
                                        <Link to="/contact-us" style={{ backgroundColor: "#d21d33" }} className="inline-flex items-center justify-center rounded-md  text-white px-7 py-[14px] text-center text-base font-medium text-dark shadow-1 transition duration-300 ease-in-out hover:bg-gray-2 hover:bg-blue-950">
                                            Contact Us
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <Fragment>
            <BannerSection />
        </Fragment>
    );
}

export default Banner;

