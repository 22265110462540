import { Fragment } from 'react';
import {
  BrowserRouter as HashRouter,
  Routes,
  Route
} from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import GalleryPage from "./components/Pages/Gallery";
import ContactUs from "./components/Pages/ContactUs";
import WebApplicationDevelopment from "./components/Pages/product/WebApplicationDevelopment";
import MobileApplicationDevelopment from "./components/Pages/product/MobileApplicationDevelopment";
import APIDevelopment from "./components/Pages/product/APIDevelopment";
import DatabaseDesignAndManagement from "./components/Pages/product/DatabaseDesignAndManagement";
import CloudServicesAndDeployment from "./components/Pages/product/CloudServicesAndDeployment";

function App() {
  return (
    <HashRouter basename='/' >
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/gallery' element={<GalleryPage />} />
        <Route path='contact-us' element={<ContactUs />} />
        <Route path='web_application_development' element={<WebApplicationDevelopment />} />
        <Route path='mobile_application_development' element={<MobileApplicationDevelopment />} />
        <Route path='api_application' element={<APIDevelopment />} />
        <Route path='database_design_and_management' element={<DatabaseDesignAndManagement />} />
        <Route path='cloud_services_and_deployment' element={<CloudServicesAndDeployment />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;