import { Fragment } from 'react';

const ServiceCard = ({ icon, title, details }) => {
    return (
        <Fragment>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                <div className="mb-9 rounded-[20px] bg-white p-10 shadow-2 hover:shadow-lg dark:bg-dark-2 md:px-7 xl:px-10">

                    <h4 className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                        {title}
                    </h4>
                    <p className="text-body-color dark:text-dark-6">{details}</p>
                </div>
            </div>
        </Fragment>
    );
};

const OurService = props => {

    const Service = () => {

        return (
            <section className="bg-gray-50 dark:bg-dark lg:pb-[90px]  pb-12 pt-10">
                <div className="container mx-auto">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="max-w-2xl mb-12 mx-auto text-center">
                                <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                                    Our Services
                                </h2>
                                <p className="text-base text-body-color dark:text-dark-6">
                                    our team of skilled professionals is dedicated to providing exceptional service every step of the way. We pride ourselves on being responsive, reliable, and easy to work with
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="-mx-4 flex flex-wrap">
                        <ServiceCard
                            title="Managed IT Services"
                            details="Comprehensive IT support and proactive management, ensuring your systems run smoothly and securely, minimizing downtime and enhancing productivity."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                        <ServiceCard
                            title="Cybersecurity Solutions"
                            details="Advanced protection against cyber threats with multi-layered security measures, including firewall management, threat detection, and response."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                        <ServiceCard
                            title="Cloud Services"
                            details="Scalable cloud solutions, from migration to management, enabling flexible, reliable, and cost-effective access to your data and applications."
                            icon=<img src="icon/design.svg" width="36" height="36" />

                        />
                        <ServiceCard
                            title="IT Consulting"
                            details="Expert advice and strategic planning to align your IT initiatives with business goals, ensuring efficient technology integration and innovation."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                        <ServiceCard
                            title="Modernization and Upgrades"
                            details="Our modernization and upgrade services are designed to enhance the performance, reliability, and safety of your elevators while extending their lifespan."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                        <ServiceCard
                            title="Software Development"
                            details="Custom software solutions tailored to your business needs, from concept and development to deployment and ongoing support."
                            icon=<img src="icon/design.svg" width="36" height="36" />
                        />
                    </div>
                </div>
            </section>
        );
    };

    return (
        <Fragment>
            <Service />
        </Fragment>
    );
};

export default OurService;

