import { Fragment } from 'react';

const AboutCompany = props => {

    const About = () => {

        return (
            <main className="py-6 px-4 sm:p-6 md:py-10 md:px-8">
                <div className="grid grid-cols-2 lg:gap-x-20 lg:grid-cols-2 max-w-7xl  mx-auto  ">
                    <div className="">
                        <h1 className="mt-1 text-lg font-semibold text-white sm:text-slate-900 md:text-2xl dark:sm:text-white">Who Are We?</h1>
                        <p className="mt-4 text-sm leading-6 col-start-1">
                            Caperbit is a leading web and mobile app development company dedicated to crafting innovative digital solutions that empower businesses to thrive in the digital age. With a team of seasoned professionals and a passion for technology, we specialize in delivering custom software solutions tailored to meet the unique needs of our clients.

                        </p>
                    </div>
                    <div className="">
                        <h1 className="mt-1 text-lg font-semibold text-white sm:text-slate-900 md:text-2xl dark:sm:text-white"> Our Mission</h1>
                        <p className="mt-4 text-sm leading-6 col-start-1">
                            At Caperbit, our mission is to leverage cutting-edge technologies and industry best practices to create impactful digital experiences that drive growth, foster engagement, and exceed client expectations.
                        </p>
                    </div>
                </div>
            </main >
        );
    };

    return (
        <Fragment>
            <About />
        </Fragment>
    );
};

export default AboutCompany;

