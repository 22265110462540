import { Fragment } from 'react';
import HeaderSection from '../../Layout/HeaderSection';
import PageFeaturesSection from '../product/PageFeaturesSection'
import WayChooseSection from '../product/WayChooseSection'

import GetInTouch from '../../Common/GetInTouch';
const data = require('../../../assets/database/WebApplicationDevelopment.json');
const WebApplicationDevelopment = props => {
    return (
        <Fragment>
            <HeaderSection title="Web Application Development" />
            <PageFeaturesSection page_name="WebApplicationDevelopment.json" />
            <WayChooseSection page_name="WebApplicationDevelopment.json" section_title="Why Choose Caperbit for Web Application Development?" />
            <section className="text-gray-700 ark:bg-dark lg:pb-[90px]  pb-12 pt-10">
                <GetInTouch title="Get in Touch:" description={data.get_in_touch} />
            </section>

            <section className="text-gray-700 ark:bg-dark lg:pb-[90px]  pb-12 pt-10">
                <GetInTouch title="Get in Touch:" description={data.get_in_touch} />
            </section>
        </Fragment>
    );
};
export default WebApplicationDevelopment;