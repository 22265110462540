import { Fragment } from 'react';
import HeaderSection from '../../Layout/HeaderSection';
import PageFeaturesSection from './PageFeaturesSection'
import WayChooseSection from './WayChooseSection'
import GetInTouch from '../../Common/GetInTouch';

const data = require('../../../assets/database/CloudServicesAndDeployment.json');
const MRElevator = props => {
    return (
        <Fragment>
            <HeaderSection title=" Cloud Services And Deployment" />
            <PageFeaturesSection page_name="CloudServicesAndDeployment.json" />
            <WayChooseSection page_name="CloudServicesAndDeployment.json" section_title="Why Choose Caperbit for Cloud Services and Deployment?" />
            <section className="text-gray-700 ark:bg-dark lg:pb-[90px]  pb-12 pt-10">
                <GetInTouch title="Get in Touch:" description={data.get_in_touch} />
            </section>
        </Fragment>
    );
};

export default MRElevator;